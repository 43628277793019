.container1{
    display: flex;
    padding-left: 200px;
    padding-top: 12px;
}

.images{
    opacity: 0;
    animation: stagger ease-in 0.3s forwards 1; 
    animation-delay: calc(var(--order) * 0.2s);
    height: 40px;
    width: 40px;
    margin: 5px;
}

.btn{
    opacity: 0;
    animation: stagger ease-in 0.3s forwards 1; 
    animation-delay: calc(var(--order) * 0.2s);
    margin: 5px;
}


@keyframes stagger { 
from { 
  opacity: 0; 
  transform: translateX(70px);
}
to{
  opacity: 1; 
  transform: translateX(0px);
}
}