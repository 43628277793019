.Title{
    display:block;
    text-align: left;
}

.ResumeSection{
    display: block;
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 200px;
    
}

.flexible{
    display: flex;
    justify-content: center;
}