.AboutMeSection{
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 50px;
}

.AboutMeContent{
    display: flex;
    padding-top: 30px;
    padding-right: 100px;
    padding-left: 150px;
    padding-bottom: 100px;
    align-items: center;
    text-align: left;
}

.headshot{
    width: 350px;
    height: 375px;
    margin-right: 50px;
}