.container{
    display:flex;
}

.card1{
    height: 400px;
    box-shadow: 5px 5px 10px black;
}

.img{
    height: 400px;
    width: 500px;
}

.intro{
    padding-top: 10px;
}

.card1:hover{
    cursor: pointer;
    transform: scale(1.05);
}