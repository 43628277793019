.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Intro{
  display: flex;
  padding-left: 250px;
  font-size: 40px;
}
.App-link {
  color: #61dafb;
}
.IntroText{
  font-family: 'Raleway', sans-serif;
  opacity: 0;
  font-size: 90px;
  animation: stagger ease-in 0.3s forwards 1; 
  animation-delay: calc(var(--order) * 0.2s);
}

@keyframes stagger { 
  from { 
    opacity: 0; 
    transform: translateY(80px);
  }
  to{
    opacity: 1; 
    transform: translateY(0px);
  }
}

.IntroDesc{
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 100px;
  padding-bottom: 0px;
  display: block;
  text-align: left;
}

